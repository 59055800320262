const LOCALES = {
  EN_CA: 'en-ca',
  EN_AU: 'en-au',
  EN_NZ: 'en-nz',
  EN_HK: 'en-hk',
  FR_CA: 'fr-ca',
};

/** @type {import('next-i18n-router/dist/types').Config} */
const i18nConfig = {
  locales: Object.values(LOCALES),
  defaultLocale: LOCALES.EN_CA,
  localeDetector: false,
  serverSetCookie: 'never',
};

module.exports = i18nConfig;
module.exports.LOCALES = LOCALES;
